export default {
  methods: {
    $_questionsCounter(q) {
      if (['s', 'm', 'a'].includes(q.type)) {
        return q.body.length
      } if (q.type === 'g') {
        return q.body[0].task.match(/@question_gap/g).length
      }
      return 1
    },
  },
}
