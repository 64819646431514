<template>
  <vue-countdown
    :time="time"
    @progress="$emit('progress', $event)"
    @end="$emit('end')"
  >
    <template slot-scope="props">
      <h3 class="text-white p-0 m-0">
        {{ props.hours >= 10 ? props.hours : `0${props.hours}` }}:{{ props.minutes >= 10 ? props.minutes : `0${props.minutes}` }}:{{ props.seconds >= 10 ? props.seconds : `0${props.seconds}` }}
      </h3>
    </template>
  </vue-countdown>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    VueCountdown,
  },
  props: {
    time: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style>

</style>
