<template>
  <b-modal ok-only id="answers-review-modal" :title="$t('tests.review_your_answers')">
    <b-row>
      <b-col v-for="(answer, index) in answers" :key="index" md="4">
        Q{{ index + 1 }}: {{ answer.val }}
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
  },
  props: {
    answers: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
