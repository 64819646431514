<template>
  <div class="p-1" v-on="$listeners">
    <b-card
      v-if="timer"
      no-body
      class="position-absolute bg-info p-50 text-center text-white mb-0"
      style="top: 1px; right: 1%; z-index: 50; width: auto"
    >
      <countdown-timer
        :time="getTimeLeft"
        @progress="timerProgress"
        @end="timeEnd"
      />
    </b-card>
    <b-tabs
      v-if="GET_LESSON.lesson_relation.length && lesson_history_id"
      v-model="active_tab"
      @input="tabChanged"
    >
      <b-tab
        v-for="(part, index) in GET_LESSON.lesson_relation"
        :key="part.id"
        :title="part.title"
        lazy
      >
        <div style="height: calc(100vh - 140px)">
          <custom-dragbar v-if="part.options.content" class="h-100">
            <template #left-side>
              <div
                id="left-side"
                class="px-1 h-100"
                @contextmenu.prevent="openContextMenu($event, 'left-side')"
              >
                <b-card
                  id="content-container"
                  class="h-100"
                  @contextmenu.prevent="onRightClick"
                >
                  <all-doc-viewer
                    v-if="part.options.is_file"
                    :src="content_url"
                  />
                  <div
                    v-else
                    class="h-100 sdsdasdsdsdsd"
                    style="overflow-y: auto"
                    @keyup.enter="runHighlight"
                    @touchend="runMobileHighlight($event)"
                    v-html="part.description"
                  />
                </b-card>
              </div>
            </template>
            <template #right-side>
              <div
                class="px-1 h-100"
                id="right-side"
                @contextmenu.prevent="openContextMenu($event, 'right-side')"
              >
                <b-card id="test-container" class="h-100 pb-3">
                  <!-- {{ test_answers[index] }} -->
                  <test-container
                    :is-first-render="is_first_render"
                    :shuffle-questions="part.options.questions_shuffle"
                    :shuffle-answers="part.options.answers_shuffle"
                    :audio-track-id="part.options.audio_track"
                    :selections="part.questions"
                    :list-style-type="part.options.listStyleType"
                    :saved-answers="
                      is_first_render ? [] : test_answers[index].questions
                    "
                    @answersChange="answersChange($event, part.id, index)"
                    @questionsFetched="saveQuestions($event, index)"
                  />
                </b-card>
              </div>
            </template>
          </custom-dragbar>
          <b-card
            v-else
            id="sandbox"
            class="h-100 pb-3"
            @contextmenu.prevent="openContextMenu($event, 'sandbox')"
            @keyup.enter="runHighlight"
          >
            <!-- {{ test_answers[index] }} -->
            <test-container
              :is-first-render="is_first_render"
              :selections="part.questions"
              :audio-track-id="part.options.audio_track"
              :list-style-type="part.options.listStyleType"
              :saved-answers="
                is_first_render ? [] : test_answers[index].questions
              "
              @answersChange="answersChange($event, part.id, index)"
              @questionsFetched="saveQuestions($event, index)"
            />
            <!-- :saved-answers="is_first_render ? [] : test_answers[index].questions" -->
          </b-card>
        </div>
      </b-tab>
    </b-tabs>
    <section
      id="test-footer"
      class="position-absolute pr-3 shadow-lg py-1 w-100 bg-light"
      style="bottom: 0"
    >
      <!-- {{ navigation_btns[active_tab] }}
      {{ active_tab }} -->
      <!-- {{ getAnswersForNavigation.flat().map((el, index) => ({key: el.key, val: el.answers[index]})) }} -->
      <!-- {{ getAnswersForNavigation.questions.flatMap(el => el.answers).map(a => ({ q: '', a: a ? a : null })) }} -->
      <!-- {{ getActiveTabAnswers }} -->
      <div class="d-flex justify-content-between res__btn">
        <div class="w-75 d-flex circle_buttons" style="overflow-x: auto">
          <b-button
            v-for="(btn, index) in getActiveTabAnswers"
            :key="index"
            :variant="String(btn.val).length ? 'primary' : 'outline-primary'"
            pill
            class="ml-25"
            size="sm"
            @click="goIntoView(btn.key)"
          >
            {{ index + 1 }}
          </b-button>
        </div>
        <div class="d-flex justify-content-end ress__jus">
          <!--          <b-button variant="warning" class="mr-2" v-b-modal.answers-review-modal>-->
          <!--            {{ $t('tests.review_answers') }}-->
          <!--          </b-button>-->
          <b-button class="mr-2" @click="abortLesson">
            {{ $t("tests.abort") }}
          </b-button>
          <b-button
            v-if="$can('manage', 'student')"
            variant="primary"
            @click="finishTest"
          >
            {{ $t("tests.complete") }}
          </b-button>
        </div>
      </div>
    </section>
    <time-end-modal @abort="abortLesson" @send="finishTest" />
    <review-answers-modal :answers="navigation_btns.flat()" />
    <vue-context ref="menu">
      <li class="px-2">
        <b-link
          class="d-flex align-items-center cursor-pointer"
          @click="runHighlight($event, 'yellow')"
        >
          <div style="width: 60px; height: 20px; background-color: yellow"></div>
          <span class="ml-75">Highlight</span>
        </b-link>
      </li>
      <li class="px-2">
        <b-link
          class="d-flex align-items-center cursor-pointer"
          @click="runHighlight($event, 'greenyellow')"
        >
          <div style="width: 60px; height: 20px; background-color: greenyellow;"></div>
          <span class="ml-75">Highlight</span>
        </b-link>
      </li>
      <li class="px-2">
        <b-link
          class="d-flex align-items-center cursor-pointer"
          @click="runHighlight($event, 'red')"
        >
          <div style="width: 60px; height: 20px; background-color: red;"></div>
          <span class="ml-75">Highlight</span>
        </b-link>
      </li>
      <li class="px-2">
        <b-link
          class="d-flex align-items-center cursor-pointer"
          @click="cancelHighlight($event)"
        >
          <span class="ml-75">Remove highlight</span>
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BButton, BLink } from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import VueContext from 'vue-context'
import customDragbar from "@/components/customDragbar.vue";
import allDocViewer from "@/components/AllDocViewer.vue";
import testContainer from "./components/testContainer.vue";
import countdownTimer from "@/components/countdownTimer.vue";
import timeEndModal from "./components/modals/timeEndModal.vue";
import reviewAnswersModal from "./components/modals/reviewAnswersModal.vue";
import useJwt from "@/auth/jwt/useJwt";
import { doHighlight, deserializeHighlights, serializeHighlights, removeHighlights, optionsImpl } from "@/../node_modules/@funktechno/texthighlighter/lib/index";

export default {
  components: {
    BTabs,
    BTab,
    customDragbar,
    BCard,
    testContainer,
    allDocViewer,
    BButton,
    countdownTimer,
    timeEndModal,
    reviewAnswersModal,
    BLink,
    VueContext,
  },
  data() {
    return {
      active_tab: null,
      content_url: '',
      test_answers: [],
      timer: false,
      saved_lesson: {},
      is_first_render: true,
      lesson_history_id: null,
      navigation_btns: [],
      sel: '',
      container_id: 'sandbox',
      range: null,
    }
  },
  computed: {
    ...mapGetters("courses", [
      "FETCH_ONE_CONTENT",
      "GET_LESSON",
      "GET_ONE_CHAPTER",
      "GET_ONE_COURSE",
    ]),
    // getContentUrl() {
    //   if (this.GET_LESSON.lesson_relation.length && this.GET_LESSON.lesson_relation[this.active_tab].contents.length) {
    //     return this.GET_LESSON.lesson_relation[this.active_tab].contents[0].file
    //   }
    //   return ''
    // },
    getTimeLeft() {
      if (this.is_first_render) {
        return this.GET_LESSON.options
          ? Number(this.GET_LESSON.options.time) * 60 * 1000
          : 0;
      }
      return Number(localStorage.getItem("timeLeft")) * 60 * 1000;
    },
    getActiveTabAnswers() {
      // return this.test_answers.map(el => el.questions.flatMap((q, index) => [{ key: q.question, answers: q.answers }]))
      // return this.test_answers.map(el => (el.questions)).map(q => ({ key: q.question, answers: q.answers }))
      return this.navigation_btns[this.active_tab];
    },
    // getAnswersForNavigation() {
    //   return this.getActiveTabAnswers
    // },
  },
  async mounted() {
    await this.refresh();
  },
  beforeDestroy() {
    this.SET_LESSON({ lesson_relation: [] });
  },
  methods: {
    ...mapActions("courses", [
      "FETCH_LESSON",
      "FETCH_ONE_CHAPTER",
      "FETCH_ONE_COURSE",
      "CREATE_LESSON_VIEW_HISTORY",
      "UPDATE_LESSON_VIEW_HISTORY",
      "CHECK_TEST",
      "FETCH_ONE_LESSON_HISTORY",
    ]),
    ...mapMutations("breadcrumb", ["UPDATE_BREADCRUMB"]),
    ...mapMutations("courses", ["SET_LESSON"]),
    updateBreadcrumb() {
      const [first, second, third, fourth, fifth] =
        this.$_removeObjectConnection(this.$route.meta.breadcrumb);
      second.text = this.GET_ONE_COURSE.title;
      second.to = `/course/course-administrations/theme-list/${this.GET_ONE_COURSE.id}`;
      third.text = this.GET_ONE_CHAPTER.title;
      fourth.text = this.GET_LESSON.title;
      this.UPDATE_BREADCRUMB([first, second, third, fourth, fifth]);
    },
    async refresh() {
      const id = JSON.parse(localStorage.getItem("savedLesson"))?.id || 0;
      if (
        !localStorage.getItem("savedLesson") ||
        id !== Number(this.$route.params.id)
      ) {
        this.FETCH_LESSON(this.$route.params.id)
          .then(() => {
            this.saved_lesson = this.$_removeObjectConnection(this.GET_LESSON);
            this.timer =
              this.GET_LESSON.options && this.GET_LESSON.options.time;
            // if (this.$can('manage', 'student')) {
            this.CREATE_LESSON_VIEW_HISTORY({
              contents: this.saved_lesson,
              lesson: this.$route.params.id,
              student: useJwt.getUserData().contact.id,
              // start_time: new Date().toISOString(),
            }).then((res) => {
              this.lesson_history_id = res.data.id;
              sessionStorage.setItem("lesson_history_id", res.data.id);
            });
            // }
          })
          .catch((err) => {
            if (err.response.status === 406) {
              this.$_errorToast(this.$t("courses.not_allowed_to_view"));
              this.$router.go(-1);
            }
          });
      } else {
        this.is_first_render = false;
        this.SET_LESSON(JSON.parse(localStorage.getItem("savedLesson")));
        this.lesson_history_id = sessionStorage.getItem("lesson_history_id");
        this.test_answers =
          JSON.parse(sessionStorage.getItem("test_answers")) ||
          this.$_removeObjectConnection(this.GET_LESSON.lesson_relation);
        this.timer = this.GET_LESSON.options && this.GET_LESSON.options.time;
      }
    },
    getContentUrl(contents) {
      return contents[0].file;
    },
    tabChanged(index) {
      if (
        this.GET_LESSON.lesson_relation[index].options.content &&
        this.GET_LESSON.lesson_relation[index].options.is_file
      ) {
        this.content_url =
          this.GET_LESSON.lesson_relation[index].contents[0].file;
      }
    },
    finishTest() {
      this.UPDATE_LESSON_VIEW_HISTORY({
        id: sessionStorage.getItem("lesson_history_id"),
        answers: this.test_answers,
        // end_time: new Date().toISOString(),
      }).then(() => {
        this.CHECK_TEST(sessionStorage.getItem("lesson_history_id")).then(
          () => {
            this.$router.replace({
              name: "test-report-view",
              params: { id: sessionStorage.getItem("lesson_history_id") },
            });
            sessionStorage.removeItem("lesson_history_id");
          }
        );
        localStorage.removeItem("savedLesson");
        localStorage.removeItem("timeLeft");
      });
    },
    answersChange(answers, id, index) {
      // this.test_answers[index] = { lesson_relation: id, questions: answers }
      // console.log('here', answers.map(el => el.answers.map(q => ({ val: q || null, key: el.question }))))
      const a = answers.flatMap((el) =>
        el.answers.map((q, indx) => ({
          val: q,
          key: `${el.question}-${indx + 1}`,
        }))
      );
      this.$set(this.navigation_btns, index, a);
      this.$set(this.test_answers, index, {
        lesson_relation: id,
        questions: answers,
      });
      sessionStorage.setItem("test_answers", JSON.stringify(this.test_answers));
    },
    saveQuestions(questions, index) {
      this.saved_lesson.lesson_relation[index].questions = questions;
      this.saved_lesson.lesson_relation[
        index
      ].options.questions_shuffle = false;
      this.saved_lesson.lesson_relation[index].options.answers_shuffle = false;
      localStorage.setItem("savedLesson", JSON.stringify(this.saved_lesson));
      // this.test_answers = this.saved_lesson.lesson_relation.map(el => ({
      //   lesson_relation: el.id, questions: [],
      // }))
      // this.saved_lesson.lesson_relation.forEach(el => {
      //   console.log('sjs')
      //   this.test_answers.push({ lesson_relation: el.id, questions: [] })
      // })
      // sessionStorage.setItem('test_answers', JSON.stringify(this.test_answers))
      this.UPDATE_LESSON_VIEW_HISTORY({
        contents: this.saved_lesson,
        id: this.lesson_history_id,
      });
    },
    timerProgress(data) {
      // console.log(data.minutes + data.seconds / 60)
      localStorage.setItem(
        "timeLeft",
        JSON.stringify(data.minutes + data.seconds / 60)
      );
    },
    timeEnd() {
      // this.$bvModal.show('time-end-modal')
      // localStorage.removeItem('savedLesson')
      this.finishTest();
    },
    goIntoView(k) {
      console.log(k);
      document.getElementById(`${k}`).scrollIntoView({ behavior: "smooth" });
    },
    abortLesson() {
      localStorage.removeItem("savedLesson");
      localStorage.removeItem("timeLeft");
      sessionStorage.removeItem("lesson_history_id");
      this.$router.go(-1);
    },
    checkMark(val) {
      if (!val) {
        return "outline-primary";
      }
      if (!val.length) {
        return "outline-primary";
      }
      return "primary";
    },
    async getTimeLeft1() {
      if (this.is_first_render) {
        return this.GET_LESSON.options
          ? Number(this.GET_LESSON.options.time) * 60 * 1000
          : 0;
      }
      if (localStorage.getItem("timeLeft")) {
        return Number(localStorage.getItem("timeLeft")) * 60 * 1000;
      }
      const history = await this.FETCH_ONE_LESSON_HISTORY(
        sessionStorage.getItem("lesson_history_id")
      );
      return (
        new Date(history.created_date).getTime() +
        this.GET_LESSON.options.time * 60 * 1000
      );
    },
    onRightClick() {
      console.log('rightclick')
    },
    highlight() {
      this.sel = window.getSelection()
      if (this.sel.rangeCount && this.sel.getRangeAt) {
        this.range = this.sel.getRangeAt(0)
        console.log('🚀 ~ highlight ~ this.sel:', this.range)
      }
      // Set design mode to on
      document.designMode = 'on'
      if (this.range) {
        this.sel.removeAllRanges()
        this.sel.addRange(this.range)
        console.log('ghjk', this.sel)
      }
      // Colorize text
      document.execCommand('ForeColor', true, 'yellow')
      // Set design mode to off
      document.designMode = 'off'
      console.log('ghjk')
    },
    runMobileHighlight(id) {
      const selection = document.getSelection()
      if (selection) {
        const domEle = document.getElementById(id)
        const options = {}
        if (domEle) {
            const highlightMade = doHighlight(domEle, true, options)
        }
      }
    },
    runHighlight(ev, col) {
      console.log('color', col)
      // run mobile a bit different
      // if (this.isMobile()) return
      const domEle = document.getElementById(this.container_id)
      const options = { color: col }
      if (domEle) {
        console.log('fghjk', domEle)
        const highlightMade = doHighlight(domEle, true, options)
      }
    },
    openContextMenu(ev3nt, id) {
      this.container_id = id
      this.$refs.menu.open(ev3nt)
    },
    cancelHighlight() {
      const domEle = document.getElementById(this.container_id)
      const options = { color: 'white' }
      if (domEle) {
        const highlightMade = doHighlight(domEle, true, options)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-context.scss";

#content-container table {
  border-color: red;
}
#test-container {
  table {
    border-color: red;
  }
}
.text-big {
  font-size: 1.4em;
}
@media (max-width: 500px) {
  .ress__jus {
    justify-content: space-between !important;
    padding-right: 10px;
    padding-left: 10px;
  }
  .unselectable {
    display: block !important;
  }

  .unselectable div {
    width: 100% !important;
  }
  #test-footer {
    position: fixed !important;
    z-index: 9999;
  }

  .res__btn {
    display: block !important;

    .circle_buttons {
      width: 100% !important;
      justify-content: center;
      padding-bottom: 20px;
    }
  }
}
</style>
