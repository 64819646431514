<template>
  <div
    class="h-100 pt-4"
  >
    <div
      class="h-100 pb-5"
      style="overflow-y: auto"
    >
      <div
        v-if="audio_track_url"
        class="audio-container"
      >
        <vue-plyr
          ref="audio"
          :options="options"
        >
          <audio
            controls
            crossorigin
            playsinline
            autoplay
          >
            <source
              :src="audio_track_url"
            >
            Your browser does not support the audio element.
          </audio>
        </vue-plyr>
      </div>
      <div
        v-for="(question, index) in questions"
        :key="question.id"
      >
        <test-preview
          :list-style-type="listStyleType"
          :questions-body="question"
          :saved-answers="isFirstRender ? [] : answers[index].answers"
          @answersChanged="changeAnswers($event, question.id, index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VuePlyr from 'vue-plyr'
import testPreview from '@/views/modules/Test/components/testPreview.vue'
import formatter from '@/mixins/formmatter'
import 'vue-plyr/dist/vue-plyr.css'
import counters from '@/mixins/counters'

export default {
  components: {
    testPreview,
    VuePlyr,
  },
  mixins: [formatter, counters],
  props: {
    listStyleType: {
      type: String,
      required: false,
      default: '',
    },
    selections: {
      type: Array,
      required: false,
      default: () => [],
    },
    savedQuestions: {
      type: Array,
      required: false,
      default: () => [],
    },
    shuffleQuestions: {
      type: Boolean,
      required: false,
      default: false,
    },
    shuffleAnswers: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFirstRender: {
      type: Boolean,
      required: false,
      default: false,
    },
    audioTrackId: {
      type: Number,
      required: false,
      default: null,
    },
    savedAnswers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      questions: [],
      answers: [],
      db_req: null,
      db: [],
      options: {
        seekTime: 2,
      },
      audio_track_url: '',
    }
  },
  watch: {
    // selections() {
    //   this.questions = []
    //   console.log('watch')
    //   this.refresh()
    // },
    savedAnswers() {
      // this.answers = this.savedAnswers
    },
  },
  beforeMount() {
    this.answers = this.savedAnswers
  },
  mounted() {
    this.refresh()
    this.getAudioTrack()
    // this.answers = this.savedAnswers
  },
  methods: {
    ...mapActions('questions', ['FETCH_ONE_QUESTION']),
    ...mapActions('content', ['FETCH_ONE_FOLDER']),
    async refresh() {
      if (this.isFirstRender) {
        await this.selections.reduce(async (a, q, index) => {
        // Wait for the previous item to finish processing
          await a
          // Process this item
          const question = await this.FETCH_ONE_QUESTION(q)
          if (this.shuffleQuestions) {
            question.body = this.$_arrayShuffler(question.body)
            if (this.shuffleAnswers) {
              question.body.forEach(el => {
                // eslint-disable-next-line no-param-reassign
                el.options = this.$_arrayShuffler(el.options)
              })
            }
          }
          if (!this.questions.map(el => el.id).includes(question.id)) {
            this.questions.push(question)
            this.changeAnswers([], question.id, index)
          }
        }, Promise.resolve())
        this.$emit('questionsFetched', this.questions)
      } else {
        this.questions = this.selections
        this.selections.forEach((s, index) => {
          this.changeAnswers(this.savedAnswers[index].answers, s.id, index)
        })
      }
      // this.selections.forEach(async element => {
      //   const a = await this.FETCH_ONE_QUESTION(element)
      //   this.questions.push(a)
      // })
    },
    getAudioTrack() {
      if (this.audioTrackId) {
        this.FETCH_ONE_FOLDER(this.audioTrackId).then(res => {
          this.audio_track_url = res.file
        })
      }
    },
    changeAnswers(answers, id, index) {
      this.answers[index] = {
        question: id,
        answers,
        order: this.questions[index].body.map(el => el.id),
      }
      this.questions[index].answers = answers
      this.$emit('answersChange', this.answers)
    },
  },
}
</script>

<style>
.audio-container{
  position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
}

.audio-container .plyr__controls .plyr__menu button {
  display: none;
}
</style>
