<template>
  <b-modal
    id="time-end-modal"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    size="lg"
    centered
    hide-header-close
    :ok-title="$t('general.send')"
    :cancel-title="$t('tests.abort')"
    @cancel="$emit('aborted')"
    @ok="$emit('send')"
  >
    Time is up!!! Send results or abort
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
}
</script>

<style>

</style>
